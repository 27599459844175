import Header from "./Header";
import Sidebar from "./sidebar";

export default function Layout({ children }: {
  children: React.ReactNode
}) {
  return (
    <div className="h-screen w-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white dark:bg-gray-700 text-black dark:text-white">
      <Header />
      <Sidebar />
      <div className="bg-sky-100 dark:bg-[#1D272E] fixed  flex flex-col top-14 left:64 md:left-64 right-0 bottom-0 ">
        {children}
      </div>
    </div>

  );
}