
const host = 'https://api.hanzotruong.com'

export const fetchPosts = async (page: number = 0, size: number = 10, status: string = '') => {
    const res = await fetch(`${host}/posts?status=${status}&page=${page}&size=${size}`)
    return res.json()
}

export const fetchPostById = async (id: string) => {
    const res = await fetch(`${host}/posts/${id}`)
    return res.json()
}

export const createPost = async (title: string, content: string) => {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')

    const request: RequestInfo = new Request(`${host}/posts`, {
        // We need to set the `method` to `POST` and assign the headers
        method: 'POST',
        headers: headers,
        // Convert the user object to JSON and pass it as the body
        body: JSON.stringify({ 'title': title, 'content': content })
    })


    const res = await fetch(request)
    return res.json()
}

export const updatePost = async (id: string, updateData: { title?: string, content?: string, published?: boolean }) => {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')

    const request: RequestInfo = new Request(`${host}/posts/${id}`, {
        // We need to set the `method` to `POST` and assign the headers
        method: 'PUT',
        headers: headers,
        // Convert the user object to JSON and pass it as the body

        body: JSON.stringify(updateData)
    })


    const res = await fetch(request)
    return res.json()
}

export const deletePost = async (id: string) => {
    const headers: Headers = new Headers()
    headers.set('Content-Type', 'application/json')
    headers.set('Accept', 'application/json')

    const request: RequestInfo = new Request(`${host}/posts/${id}`, {
        // We need to set the `method` to `POST` and assign the headers
        method: 'DELETE',
        headers: headers,
    })

    // Send the request and print the response
    const res = await fetch(request)
    return res.json()
}