import { Buffer } from 'buffer'
import { download, generateCsv, mkConfig } from 'export-to-csv'
import matter from 'gray-matter'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Layout from '../components/Layout'
import { fetchPosts } from "../utils/api"

// @ts-ignore
window.Buffer = Buffer;

export default function ExportRecords() {
  const [listPost, setListPost] = useState<any[]>([])

  const _fetctPosts = async () => {
    const { data } = await fetchPosts(0, 999, 'published')
    if (!data) return
    const { posts } = data


    var detailedPosts: any = []
    if (!posts) return
    for (var i = 0; i < posts.length; i++) {
      const p = posts[i]
      console.log('p: ', p)
      const matterResult = matter((p as any).content)

      detailedPosts = [
        ...detailedPosts,
        {
          title: p.title,
          description: matterResult.data.description,
          thumbnail: matterResult.data.thumbnail,
          slug: p.slug
        }
      ]
    }

    setListPost(detailedPosts)
  }

  useEffect(() => {
    _fetctPosts()
  }, [])

  const _onClickedExport = () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: 'hanzotruong' });
    // Converts your Array<Object> to a CsvOutput string based on the configs
    const csv = generateCsv(csvConfig)(listPost);
    download(csvConfig)(csv)
  }

  return (
    <Layout>
      <div className='p-6'>
        <div className='flex justify-center'>
          <button onClick={_onClickedExport} className=" my-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            Export to CSV
          </button>
        </div>
        <div className=' table divide-y'>
          {
            listPost?.map((p, index) => {
              return (
                <div className='table-row  items-center'>
                  <div className=' table-cell p-3'>
                    <img src={p.thumbnail} alt="" width={200} height={200} />
                  </div>
                  <div className='table-cell p-3 align-top'>
                    <div className='font-medium mb-1'>{p.title}</div>
                    <div>{p.description}</div>
                    <Link to={'#'}>{p.slug}</Link>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </Layout>
  )
}