import { useEffect, useState } from "react";
import { Link, useNavigate, } from "react-router-dom";
import { routes } from "../App";
import Layout from "../components/Layout";
import { deletePost, fetchPosts, updatePost } from "../utils/api";

export default function ListPostPage() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([])
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const pageSize = 5

  useEffect(() => {
    const loadPosts = async () => {
      try {
        setIsLoading(true);
        const jsonRes = await fetchPosts(page, pageSize)
        setTotalPage(Math.ceil(Number(jsonRes['data']['total']) / pageSize))
        setPosts([...posts, ...jsonRes['data']['posts']] as []);

      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    loadPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleLoadMore = () => {
    if (page + 1 === totalPage) return;
    setPage(page + 1);
  }


  const handleDeletePost = (id: string) => {
    // Send the request and print the response
    deletePost(id)
      .then(resJson => {
        if (resJson['code'] === 0) {
        } else {
          alert('Có lỗi xảy ra! Vui lòng thử lại')
        }
      })
  }

  const hanleChangePublish = async (id: string, value: boolean) => {
    const res = await updatePost(id, { published: value })
    const newPosts = posts.map(p => (p as { id: string }).id === id ? res.data : p)
    setPosts(newPosts as [])
  }

  return (
    <Layout>
      <div className="h-full flex flex-col p-3">

        <nav className="flex grow-0" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li className="inline-flex items-center">
              <Link to="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                </svg>
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                </svg>
                <Link to="#" className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Danh sách bài viết</Link>
              </div>
            </li>

          </ol>
        </nav>

        <div className="grow overflow-auto mt-3 pr-3">
          {posts.map((p) => {
            const { id, title, created_at, updated_at, published } = p as { id: string, title: string, created_at: string, updated_at: string, published: boolean };
            return (
              <div key={id} className={"flex bg-white dark:bg-full dark:bg-[url('./assets/images/gradient-bg-dark.png')] dark:bg-center dark:bg-no-repeat  p-4 mb-3 border-[0.5px] border-[#435160] rounded-md shadow-sm"}>
                <div className="grow flex-col">
                  <h1 className=" font-semibold text-xl tracking-tight text-grey-900">
                    {title}
                  </h1>
                  <p className=" font-light text-xs">
                    Ngày tạo: {new Date(created_at).toLocaleDateString('vi-vn')} {new Date(created_at).toLocaleTimeString('vi-vn')}
                  </p>
                  <p className=" font-light text-xs">
                    Ngày cập nhật: {new Date(updated_at).toLocaleDateString('vi-vn')} {new Date(updated_at).toLocaleTimeString('vi-vn')}
                  </p>
                  {
                    published ?
                      <span className="inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">

                        <span className="w-2 h-2 me-1 bg-green-500 rounded-full"></span>
                        Published
                      </span>
                      :
                      <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                        <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
                        Unpublished
                      </span>
                  }
                </div>
                <ul className="flex space-x-3">
                  <li key={'key'}>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input type="checkbox" checked={published} onChange={(e) => { hanleChangePublish(id, e.target.checked) }} className="sr-only peer" />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 dark:peer-checked:bg-[#01fee2]"></div>
                      <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Công khai</span>
                    </label>
                  </li>
                  <li>
                    <button className="" onClick={() => { navigate(routes.editPost().format(id)) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>
                    </button>
                  </li>
                  <li>
                    <button className="" onClick={() => handleDeletePost(id)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>

                    </button>
                  </li>
                </ul>
              </div>
            )
          }

          )}

          <div className="flex justify-center my-5">
            {page < totalPage - 1
              ? <button onClick={handleLoadMore} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                {isLoading ?
                  <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                  </svg> : null}
                Xem thêm...
              </button>
              : null}

          </div>
        </div>

      </div>
    </Layout>
  );
}