import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { fetchPosts } from "../utils/api";

export default function DashboardPage() {
  var [posts, setPosts] = useState([])

  useEffect(() => {
    fetchPosts().then(
      (jsonRes) => {
        setPosts(jsonRes['data']['posts'])
      }
    )
  }, [])

  const getTotalPosts = () => posts.length
  const getTotalPublishedPosts = () => (posts.filter((p) => p['published'] === true)).length


  return (
    <Layout>
      <div className="flex flex-col  h-full w-full p-3">
        {/* breadcrumbs */}
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li className="inline-flex items-center">
              <Link to="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                </svg>
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                </svg>
                <Link to="#" className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Dashboard</Link>
              </div>
            </li>
          </ol>
        </nav>
        {/* end breadcrumbs */}

        <div>
          <div className="grid grid-cols-1 gap-4 px-4 mt-8 sm:grid-cols-4 sm:px-8">
            <div className="hidden flex items-center bg-white border rounded-sm overflow-hidden shadow">
              <div className=" p-4 bg-green-400"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                </path>
              </svg></div>
              <div className="px-4 text-gray-700">
                <h3 className="text-sm tracking-wider">Total Member</h3>
                <p className="text-3xl">12,768</p>
              </div>
            </div>
            <div className="flex items-center bg-white dark:bg-[url('./assets/images/texture-gradient3.jpeg')] dark:bg-full dark:bg-center p-3 ">
              <div className="p-4 bg-blue-400"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2">
                </path>
              </svg></div>
              <div className="px-4 text-gray-700">
                <h3 className="text-sm dark:text-white tracking-wider">Số bài viết</h3>
                <p className="text-3xl dark:text-white">{getTotalPosts()}</p>
              </div>
            </div>
            <div className="flex items-center bg-white dark:bg-[url('./assets/images/texture-gradient3.jpeg')] dark:bg-full dark:bg-center p-3 ">
              <div className="p-4 bg-indigo-400"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z">
                </path>
              </svg></div>
              <div className="px-4 text-gray-700">
                <h3 className="text-sm dark:text-white tracking-wider">Đã xuất bản</h3>
                <p className="text-3xl dark:text-white">{getTotalPublishedPosts()}</p>
              </div>
            </div>
            <div className="hidden flex items-center bg-white border rounded-sm overflow-hidden shadow">
              <div className="p-4 bg-red-400"><svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4">
                </path>
              </svg></div>
              <div className="px-4 text-gray-700">
                <h3 className="text-sm tracking-wider">Server Load</h3>
                <p className="text-3xl">34.12%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}