
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AuthRoute from './components/authRoute';
import NotFoundPage from './pages/404';
import DashboardPage from './pages/dashboard';
import EditPostPage from './pages/editPost';
import ExportRecords from './pages/exportRecords';
import ListPostPage from './pages/listPost';
import LoginPage from './pages/login';

export const routes = {
  login: () => {
    const path = "/login"
    const format = () => path
    return { path, format }
  },
  dashboard: () => {
    const path = "/"
    const format = () => path
    return { path, format }
  }
  ,
  listPost: () => {
    const path = "/posts"
    const format = () => path
    return { path, format }
  },
  editPost: () => {
    const path = "/posts/:id/edit"
    const format = (id: string) => path.replaceAll(':id', id)
    return { path, format }
  },
  createPost: () => {
    const path = "/posts/new/edit"
    const format = () => path
    return { path, format }
  },
  export: () => {
    const path = "/export"
    const format = () => path
    return { path, format }
  }
}

function App() {
  return (
    <Routes>
      <Route element={<AuthRoute />} >
        <Route path={routes.dashboard().path} element={<DashboardPage />} />
        <Route path={routes.listPost().path} element={<ListPostPage />} />
        <Route path={routes.editPost().path} element={<EditPostPage />} />
        <Route path={routes.createPost().path} element={<EditPostPage />} />
        <Route path={routes.export().path} element={<ExportRecords />} />
      </Route>
      <Route path={routes.login().path} element={<LoginPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;