
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/authProvider";

const AuthRoute = () => {
    const { user } = useAuth() as { user: any };
    const location = useLocation();

    return user ? (
        <Outlet />
    ) : (
        <Navigate to={'/login'} replace state={{ path: location.pathname }} />
    );
};

export default AuthRoute;