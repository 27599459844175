import { User } from "@supabase/supabase-js";
import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../supabase/client";
import Loading from "../components/loading";

const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

const login = (email: string, password: string) =>
  supabase.auth.signInWithPassword({ email: email, password: password });
const signOut = () => supabase.auth.signOut();

const AuthProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<User | null>();
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();
      const { user: currentUser } = data;
      setUser(currentUser ?? null);
      setAuth(currentUser ? true : false);
      setLoading(false);
    };

    getUser()

    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setUser(session?.user);
        setAuth(true);
      } else if (event === "SIGNED_OUT") {
        setAuth(false);
        setUser(null);
      }
    });
    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        login,
        signOut,
      }}>
      {loading ? <div className="h-screen flex flex-col"> <Loading/> </div> : children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;