import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../App";
import { useAuth } from "../context/authProvider";
import { useForm } from "../hooks/form";
const LoginPage = () => {

  const navigate = useNavigate();
  const { user, login, loading, } = useAuth() as { user: any, login: any, loading: boolean }
  const location = useLocation();

  // defining the initial state for the form
  const initialState = {
    email: "",
    password: "",
  };

  // getting the event handlers from our custom hook
  const { onChange, onSubmit, values } = useForm(
    loginUserCallback,
    initialState
  ) as { onChange: any, onSubmit: any, values: { email: string, password: string } };

  // a submit function that will execute upon form submission
  async function loginUserCallback() {
    // send "values" to database
    const {
      data: { u, session },
      error
    } = await login(values.email, values.password);
    if (error) alert(error.message);
    if (u && session) navigate(routes.dashboard().format());
  }

  return user ? <Navigate to={routes.dashboard().format()} replace state={{ path: location.pathname }} /> : (

    <section className="grid place-items-center  bg-sky-100 p-16 min-h-screen">
      <div className="flex  flex-col justify-center lg:px-8 py-10  max-w-xs overflow-hidden rounded-lg bg-white  text-cyan-800 shadow-lg">
        <img src={require("../logo.png")} className="mx-auto h-10 w-auto" alt="logo" />
        <h2 className="mt-0 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">HZT CMS</h2>


        <form className="space-y-3" onSubmit={onSubmit}>
          <div>
            <div className="mt-2">
              <input
                name='email'
                id='email'
                type='email'
                placeholder='Email'
                onChange={onChange}
                required
                className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
              />
            </div>
          </div>
          <div>
            <div className="mt-2">
              <input
                name='password'
                id='password'
                type='password'
                placeholder='Mật khẩu'
                onChange={onChange}
                required
                className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <button type='submit' className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" >Login {loading ? '(loading)' : ''}</button>
          </div>
        </form>
      </div>
    </section>

  );
};

export default LoginPage;
