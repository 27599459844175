import { createClient } from "@supabase/supabase-js";


const projectURL = "https://yldnincncarcbpjryggx.supabase.co";
const projectKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlsZG5pbmNuY2FyY2JwanJ5Z2d4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDA4NzE1NDYsImV4cCI6MjAxNjQ0NzU0Nn0.NKH9YyV8p-3Q7TIZj7WbTYN1Xcd-EXAVTIhQUhZcz64";

export const supabase = createClient(projectURL, projectKey, {
    auth: {
        storage: localStorage,
        persistSession: true
      }
});
