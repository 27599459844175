import MDEditor from '@uiw/react-md-editor';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { routes } from '../App';
import Layout from "../components/Layout";
import { createPost, fetchPostById, updatePost } from '../utils/api';
import { isNumber } from '../utils/uiltils';

export default function EditPostPage() {
  const navigate = useNavigate();

  const [originPost, setOriginPost] = useState()
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(originPost ? originPost['content'] : '');
  const [showDialog, setShowDialog] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    // Đọc dữ liệu từ localStorage với key là 'savedContent'
    var savedContent = localStorage.getItem('savedContent');
    if (!savedContent || savedContent.trim() === '') {
      savedContent = "---\nthumbnail: \n\ndescription: \n\n---\n\n"
    }

    // Nếu có dữ liệu, set giá trị cho content
    if (!(id && isNumber(id)) && savedContent) {
      setContent(savedContent);
    }
  }, [id]); // Trigger useEffect chỉ một lần khi component được render

  // Hàm xử lý sự kiện thay đổi nội dung của textarea
  const handleContentChange = (value: any) => {
    setContent(value || '')
    // Lưu nội dung vào localStorage với key là 'savedContent'
    if (!(id && isNumber(id))) {
      localStorage.setItem('savedContent', value);
    }

  };

  useEffect(() => {
    if (id && isNumber(id)) {
      fetchPostById(id).then(
        (jsonRes) => {
          setOriginPost(jsonRes['data'])
          setContent(jsonRes['data']['content'])
          setTitle(jsonRes['data']['title'])
        }
      )

    }
  }, [id])

  const toggleConfirmDialog = () => setShowDialog(!showDialog);

  const handleCreatePost = async () => {
    if (showDialog) return;
    await createPost(title, content)
      .then(resJson => {
        if (resJson['code'] === 0) {
          navigate(routes.listPost().format())
        } else {
          alert('Có lỗi xảy ra! Vui lòng thử lại')
        }
      })
  }

  const handleUpdatePost = async () => {
    if (showDialog || !id || !isNumber(id)) return;
    await updatePost(id, { title: title, content: content })
      .then(resJson => {
        if (resJson['code'] === 0) {
          navigate(routes.listPost().format())
        } else {
          alert('Có lỗi xảy ra! Vui lòng thử lại')
        }
      })
  }


  return (
    <Layout>
      <div className='flex flex-col grow overflow-hidden p-3'>
        {/* breadcrumbs */}
        <div className='flex'>
          <nav className="flex grow" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
              <li className="inline-flex items-center">
                <Link to="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                  <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                </Link>
              </li>
              <li>
                <div className="flex items-center">
                  <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                  </svg>
                  <Link to="#" className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Tạo bài viết mới</Link>
                </div>
              </li>

            </ol>
          </nav>
          <>
            <ul className='flex space-x-3'>
              <li><button onClick={toggleConfirmDialog} className='outline outline-1 outline-[#435160] px-3 py-1  text-[#01fee2] text-sm font-semibold'>Huỷ</button></li>
              <li><button onClick={isNumber(id) ? handleUpdatePost : handleCreatePost} className='outline outline-1 px-5 py-1 bg-[#01fee2] shadow-lg text-black text-sm font-semibold'>Lưu</button></li>
            </ul>

          </>
        </div>

        {/* title */}
        {showDialog ? null :
          <div className='pt-3'>
            <input key={originPost} defaultValue={originPost ? originPost['title'] : ""} onChange={(v) => setTitle(v.target.value || '')} className="block p-2.5 mb-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Đặt tiêu đề bài viết..."></input>
          </div>
        }

        {/* editor */}
        {showDialog ? null :
          <div className="grow overflow-auto">
            <MDEditor
              height={"100%"}
              value={content}
              onChange={handleContentChange}
            />
          </div>
        }

        {/* confirm dialog */}
        {showDialog ? (
          <div className="inline relative mt-3 p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <button onClick={toggleConfirmDialog} className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="deleteModal">
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              <span className="sr-only">Close modal</span>
            </button>

            <p className="mb-4 text-gray-500 dark:text-gray-300">Huỷ toàn bộ thay đổi?</p>
            <div className="flex justify-center items-center space-x-4">
              <button onClick={toggleConfirmDialog} className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                Không
              </button>
              <button onClick={() => {
                navigate(routes.listPost().format())
              }} className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                Huỷ
              </button>
            </div>
          </div>
        ) : null}
      </div>

    </Layout >
  )
}