import { Link } from "react-router-dom";
import { routes } from "../App";

export default function NotFoundPage() {
  return (
    <section className="h-screen flex flex-col justify-center bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600 dark:text-blue-500">404</h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Đã có lỗi xảy ra.</p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Trang bạn đang cố gắng truy cập không tồn tại hoặc có thể đang bảo trì. Vui lòng kiểm tra lại đường dẫn hoặc thử lại sau ít phút! </p>
          <Link to={routes.dashboard().format()} className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue-900 my-4">Trở về trang chủ</Link>
        </div>
      </div>
    </section>
  )
}